import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

const ModalItem = ({ title, show, onHide, paypal }) => {    
    return(
        <Modal show={show}
            onHide={onHide}
            centered>
            <Modal.Header closeButton><b>{title}: Opciones de Pago</b></Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                    Para hacer el pago de este producto puedes usar el botón "Comprar Ahora" de PayPal 
                    o bien, si gustas hacer una transferencia interbancaria los datos de nuestro banco se 
                    encuentran abajo.<br/><br/>
                    </Row>
                    <Row>
                        <Col>PAYPAL BUTTON{/*paypal*/}</Col>
                        <Col>--DATOS DEL BANCO--</Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalItem;