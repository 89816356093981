import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './footer.scss';

class Footer extends Component {
    render(){
        return(
            <footer className="col-sm-12 mt-10" style={{"marginTop":"30px"}}>
                <img src="logos/logo-square-black.png" alt="Logo" className="logo"/>
                <div id="social">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/NGZP.73113" _target="blank">
                                <FontAwesomeIcon icon={faInstagram} className="instagram-color" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/NGZP.73113" _target="blank">
                                <FontAwesomeIcon icon={faFacebook} className="fb-color"/>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:contacto@nathaliagutierrez.com" _target="blank">
                                <FontAwesomeIcon icon={faEnvelope} className="mail-color"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default Footer;