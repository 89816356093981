import { React, Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WOW from 'wowjs';

import PielPop from './images/piel-pop.jpg';
import Christmas from './images/christmas.jpg';
import MariaPrimavera from './images/maria-primavera.jpg';
import JardinMagico from './images/jardin-magico.jpg';
import SonrisaCaramelo from './images/sonrisa-caramelo.jpg';
import ColoresVividos from './images/colores-vividos.jpg';
import PinturaOleo from './images/pintura-oleo.jpg';
import SmashCake from './images/smash-cake.jpg';
import CorazonBukeh from './images/corazon-bukeh.jpg';
import ArbolesOverlays from './images/arboles-overlays.jpg';
import OverlaysNevados from './images/overlays-nevados.jpg';
import Confetti from './images/confetti.jpg';
import Plantas from './images/plantas.jpg';

import './tutorials.scss';
import Item from '../common/item';

class Tutorials extends Component {
	constructor() {
		super();
		this.state = {
			showing: 'all'
		}
	}

	componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

	filter = (term) => {
		if(term !== this.state.showing){
			this.setState({showing: term})
		}
	}

	render() {
		return (
			<Container className="content">
				<Row>
					<Col>
						<h1 className="wow bounceIn title flex-left">Tutoriales y Recursos</h1>
					</Col>
				</Row>
				<Row className="filters wow bounceIn">
					<Col>
						<div className={this.state.showing === 'all' ? "button selected" : "button"} onClick={() => this.filter('all')}>Todo</div>
						<div className={this.state.showing === 'tutorials' ? "button selected" : "button"} onClick={() => this.filter('tutorials')}>Tutoriales</div>
						<div className={this.state.showing === 'resources' ? "button selected" : "button"} onClick={() => this.filter('resources')}>Recursos Digitales</div>
						<div className={this.state.showing === 'actions' ? "button selected" : "button"} onClick={() => this.filter('actions')}>Actions</div>
					</Col>
				</Row>
				<Item showing={this.state.showing} 
					  title="Jardín Mágico"
					  mxn="1,500.00"
					  usd="79"
					  type="tutorials" 
					  image={JardinMagico}
					  duration="1 hora"
					  includes={<span>- 3 videos<br/>- 4 Actions<br/>- 1 brocha para Photoshop</span>} 
					  description="Aprenderás a como hacer los colores más vibrantes, 
					  sobre tonos de piel, efecto mate, crear un efecto 
					  mas suave en la imágen, nitidez y realzar detalles." />
				<Item showing={this.state.showing} 
					  title="María Primavera"
					  mxn="1,500.00"
					  usd="79"
					  type="tutorials" 
					  image={MariaPrimavera}
					  duration="1 hora 4 minutos"
					  includes={<span>- 1 video<br/>- 9 recursos digitales<br/></span>} 
					  description="Pasos manuales en Photoshop donde aprenderas a 
					  usar filtros tono durazno, efecto de pintura al 
					  óleo, efecto pintura, enfoque y nitidez." />
				<Item showing={this.state.showing} 
					  title="Sonrisa Caramelo"
					  mxn="1,500.00"
					  usd="79"
					  type="tutorials" 
					  image={SonrisaCaramelo}
					  duration="1 hora"
					  includes={<span>- 7 actions (pintura al óleo, contraste, color, nitidez, tono de piel, tono de mejillas, viñeta)<br/></span>} 
					  description="En este curso aprenderás a como trabajar la piel en 4 pasos fundamentales, aprenderas una acción para pintura al óleo, dodge and burn." />
				<Item showing={this.state.showing} 
					  title="Christmas Tutorial"
					  mxn="2,000.00" 
					  type="tutorials" 
					  image={Christmas}
					  duration="1 hora"
					  includes={<span>- 7 actions (Paleta de tonos de piel, pintura al óleo, suavidad, nitidez, colores vivos, contraste, piel pop )<br/></span>} 
					  description="Incluye pasos manuales y otros en actions, primeros pasos en camara raw, separación de frecuencias, agrega volumen a tu imágen." />
				<Item showing={this.state.showing} 
					  title="Piel Pop" 
					  mxn="250" 
					  usd="13.50" 
					  type="actions" 
					  image={PielPop}
					  includes=""
					  description="Conseguirás un efecto pop y un toque dorado sobre la piel" />
				<Item showing={this.state.showing} 
					  title="Colores Vívidos" 
					  mxn="250" 
					  usd="13.50" 
					  type="actions" 
					  image={ColoresVividos}
					  description="Con esta action conseguirás obtener mas color, contraste y volumen en los elementos de tu imágen." />
				<Item showing={this.state.showing} 
					  title="Pintura al Óleo" 
					  mxn="300" 
					  usd="16" 
					  type="actions" 
					  image={PinturaOleo}
					  description="Esta action te ayudará a creaer un efecto de pintura al óleo en los detalles de tu imágen." />
				<Item showing={this.state.showing} 
					  title="Actions Smash Cake" 
					  mxn="800" 
					  usd="42.50" 
					  type="actions" 
					  image={SmashCake}
					  description="Actions de edición rápida para sesion de fotos Smash Cake" />
				<Item showing={this.state.showing} 
					  title="Overlays Bokeh Corazón" 
					  mxn="200" 
					  usd="10.50" 
					  type="resources" 
					  image={CorazonBukeh}
					  includes={<span>- 23 overlays</span>}
					  description="Overlays Corazon Bokeh, varios overlays" />
				<Item showing={this.state.showing} 
					  title="Overlays Árboles de Navidad" 
					  mxn="200" 
					  usd="10.50" 
					  type="resources" 
					  image={ArbolesOverlays}
					  includes={<span>- 18 overlays</span>}
					  description="Overlays árboles de navidad, varios overlays" />
				<Item showing={this.state.showing} 
					  title="Overlays Nevados" 
					  mxn="200" 
					  usd="10.50" 
					  type="resources" 
					  image={OverlaysNevados}
					  includes={<span>- 28 overlays</span>}
					  description="Overlays Nevados, varios overlays" />
				<Item showing={this.state.showing} 
					  title="Overlays de Plantas" 
					  mxn="200" 
					  usd="10.50" 
					  type="resources" 
					  image={Plantas}
					  includes={<span>- 14 overlays</span>}
					  description="Overlays de plantas, varios overlays" />
				<Item showing={this.state.showing} 
					  title="Overlays de confetti San Valentín" 
					  mxn="200" 
					  usd="10.50" 
					  type="resources" 
					  image={Confetti}
					  includes={<span>- 14 overlays</span>}
					  description="Overlays de confetti para San Valentín, varios overlays" />
			</Container>
		);
	}
}

export default Tutorials;
