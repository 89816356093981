import React, { Component } from 'react';
import { Container,
            Row,
            Col,
            Form,
            FormGroup,
            FormControl,
            Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp, faFacebook } from '@fortawesome/free-brands-svg-icons';

import WOW from 'wowjs';

import './contact.scss';

class Contact extends Component {
    componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

    render() {
        return(
            <Container fluid className="content p-0">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3487.3724656006884!2d-111.00250298444136!3d29.065136682249157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce8387badada2d%3A0x7747f82b35cd350a!2sRtno.%20Signoria%209%2C%20Villa%20Bonita%20Residencial%2C%20Hermosillo%2C%20Son.!5e0!3m2!1sen!2smx!4v1611534151874!5m2!1sen!2smx"
                    className="wow bounceIn"
                    width="100%"
                    height="450"
                    style={{"border": 0}}
                    aria-hidden="false"
                    title="map"></iframe>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="wow bounceIn title">¿Necesitas más información?</h1>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Row className="text text-center wow bounceIn">
                                <Col>
                                    <p>Signoria #9, Villa Bonita</p>
                                    <p>Hermosillo, Sonora, México</p>
                                    <p>C.P. 83288</p>
                                    <p>
                                        <a href="tel:6621482690">
                                            <FontAwesomeIcon icon={faPhone} className="phone-color" /> (662) 148 2690
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="text text-center social wow bounceIn">
                                <Col>
                                    <a href="mailto:contacto@nathaliagutierrez.com.mx" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faEnvelope} className="mail-color" />
                                    </a>
                                </Col>
                                <Col>
                                    <a href="https://wa.me/6621482690" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-color" />
                                    </a>
                                </Col>
                                <Col>
                                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} className="instagram-color" />
                                    </a>
                                </Col>
                                <Col>
                                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faFacebook} className="fb-color" />
                                    </a>
                                </Col>
                            </Row>
                            <br/>
                        </Col>
                        <Col>
                            <p className="text text-small wow bounceIn">Si prefieres también puedes llenar el siguiente formulario de contacto para enviarme un mensaje.</p>
                            <Form className="wow bounceIn">
                                <FormGroup>
                                    <FormControl type="text" placeholder="Nombre" />
                                </FormGroup>
                                <FormGroup>
                                    <FormControl type="email" placeholder="Correo Electrónico" />
                                </FormGroup>
                                <FormGroup>
                                    <FormControl as="textarea" placeholder="Tu mensaje..." rows={5} />
                                </FormGroup>
                                <Button variant="primary">Enviar</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default Contact;