import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from '../routes';

import Header from '../common/header';
import Footer from '../common/footer';

import './app.scss';

function App() {
	const renderRoutes = () => (
		routes.map((route, i) => (
			<Route 
				exact
				key={i}
				path={route.path}
				render={(props) => (
					<route.component { ...props } />
				)}
			/>)
		)
	);

	return (
		<Router>
			<Container fluid className="mr-0 ml-0 pr-0 pl-0 wow fadeIn">
				<Header />
				<Switch>
					{ renderRoutes() }
				</Switch>
				<Footer />
			</Container>
		</Router>
	);
}

export default App;
