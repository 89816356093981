import React from 'react';
import { Container } from 'react-bootstrap';
import { SRLWrapper } from "simple-react-lightbox";

const options = {
  buttons: {
    backgroundColor: "rgba(140, 94, 88, 0.8)",
    iconColor: "rgba(241, 191, 152, 0.7)",
    showDownloadButton: false
  },
  settings: {
    overlayColor: "rgba(255, 237, 225, 1)",
    transitionSpeed: 1000,
    transitionTimingFunction: "linear",
  },
  thumbnails: {
    thumbnailsSize: ["120px", "100px"],
    thumbnailsOpacity: 0.4
  },
  caption: {
    captionColor: "rgba(241, 191, 152, 1)"
  },
  progressBar: {
    size: "4px",
    backgroundColor: "rgba(255, 237, 225, 1)",
    fillColor: "#AF9AB2"
  }
};

const Gallery = () => {
	return (
		<Container className="content-home">
			<SRLWrapper options={options}>
				<div id="gallery-with-links" className="content">
					<div className="row">
						<h1 className="title wow bounceIn">Galería</h1>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_despuésRGB5506.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_despuésRGB5506.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB2998-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB2998-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB3654-2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB3654-2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB3811-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB3811-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB3940-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB3940-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB4524-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB4524-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB4591-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB4591-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB4816-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB4816-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB5252-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB5252-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB5667.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB5667.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB5773.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB5773.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB6485-4.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB6485-4.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB6561.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB6561.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB6745.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB6745.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB6766.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB6766.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB6921-2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB6921-2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB8241.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB8241.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB8628-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB8628-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/_RGB9142.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/_RGB9142.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/BLANCA NIEVES.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/BLANCA NIEVES.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/CHIMENEA Alexa 2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/CHIMENEA Alexa 2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/CHIMENEAnuevaa Alexa 2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/CHIMENEAnuevaa Alexa 2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/collagejpg.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/collagejpg.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/Copia de DSC_0995-1 copy.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/Copia de DSC_0995-1 copy.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/danna.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/danna.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_0030-Recuperado copia.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_0030-Recuperado copia.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_1569 copia.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_1569 copia.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_2868-2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_2868-2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_3713 copia.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_3713 copia.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_7321 copia.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_7321 copia.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/DSC_7380.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/DSC_7380.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/facce.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/facce.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/face3.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/face3.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/Fresh Market.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/Fresh Market.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/Halloween Alexa.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/Halloween Alexa.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/IVANNA FINE ART Navidad-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/IVANNA FINE ART Navidad-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/JARDÍN 2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/JARDÍN 2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/jardín.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/jardín.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/MARIA BOSQUE 2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/MARIA BOSQUE 2.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/María con bosque.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/María con bosque.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/María El paíz de las maravillas.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/María El paíz de las maravillas.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/María_Navidad.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/María_Navidad.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/nueva.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/nueva.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/nuevaaRGB6722-1.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/nuevaaRGB6722-1.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/nuevaClluse Candy Company.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/nuevaClluse Candy Company.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/pintura circo .jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/pintura circo .jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/terminada lago...jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/terminada lago...jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/texturaa.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/texturaa.jpg" />
							</a>
						</div>
						<div className="col-md-4 col-6 col-image-with-link wow bounceIn">
							<a href="/gallery/toys 2.jpg">
								<img alt="nathalia-gutierrez" src="/gallery/thumbs/toys 2.jpg" />
							</a>
						</div>
					</div>
				</div>
			</SRLWrapper>
		</Container>
	);
};

export default Gallery;
