import React, { Component } from 'react';
import './slideshow.scss';

//images
import slide1 from './images/slide-1.jpg';
import slide2 from './images/slide-2.jpg';
import slide3 from './images/slide-3.jpg';
import slide4 from './images/slide-4.jpg';

class Slideshow extends Component {
    constructor() {
        super();
        this.state = {
            imagesList: [
                slide1,
                slide2,
                slide3,
                slide4
            ],
            currentImage: slide1
        }
    }

    changeImage = () => {
        this.setState({currentImage: this.state.imagesList[Math.floor(Math.random() * 3)]});
    }

    componentDidMount() {
        this.slideInterval = setInterval(() => {
            this.changeImage();
        }, 3000)
    }

    componentWillUnmount() {
        clearInterval(this.slideInterval);
    }

    render() {
        return(
            <div className="image-slider"
                style={{ 
                    backgroundSize: 'cover',
                    background: `url(${this.state.currentImage}) 50% no-repeat`
                }}></div>
        );
    }
}

export default Slideshow;