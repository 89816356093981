import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import WOW from 'wowjs';

import './notfound.scss';

class NotFound extends Component {
    componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

    render() {
        return(
            <Container fluid className="content p-0">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="wow bounceIn title">Ooops!</h1>
                            <h2 className="wow bounceIn text-center">Parece que la página que estás buscando no existe</h2>
                            <h3 className="wow bounceIn text-center">
                                <Link to="/"><FontAwesomeIcon icon={faArrowRight} /> Ir a inicio</Link>
                            </h3>
                        </Col>
                    </Row>                    
                </Container>
            </Container>
        );
    }
}

export default NotFound;