import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import WOW from 'wowjs';
import Slideshow from './slideshow';
import AboutMePhoto from './images/aboutme.jpg';
import ParaFotografos from './images/para_fotografos.jpeg';
import Fotografia from './images/fotografia.jpeg';
import './home.scss';
import Divider from '../common/divider';

// Stripe photos
// import stripePhoto1 from './images/stripe/1.jpg';
// import stripePhoto2 from './images/stripe/2.jpg';
// import stripePhoto3 from './images/stripe/3.jpg';
// import stripePhoto4 from './images/stripe/4.jpg';

class Home extends Component {
    componentDidMount() {
        new WOW.WOW({live: false}).init();
    }
    render(){
        return(
            <div className="content-home">
                <Slideshow />
                <Container>
                    <section className="section pb-0">
                        <span className="wow bounceIn title text-center" 
                            data-wow-delay="0.1s"
                            data-wow-duration="1s">Acerca de Mí</span>
                        <Row>
                            <Col lg="7" md="6">
                                <p className="about-text wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">Mi nombre es Nathalia Gutiérrez Zamora, y me dedico a crear mágicas historias dentro de imágenes, congelando momentos únicos.</p>
                                <p className="about-text wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">La Fotografía nos permite sacar la magia que hay dentro de nosotros.</p>
                            </Col>
                            <Col lg="5" md="6">
                                <img className="about-photo shadow wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={AboutMePhoto} />
                            </Col>
                        </Row>
                    </section>
                    <Divider />
                    <section className="section pt-0">
                        <h2 className="title wow bounceIn pt-0 text-center">Conóce más</h2>
                        <h6 className="wow text-center bounceIn pt-0 mobile-label">Click para ver más</h6>
                        <Row className="wow fadeIn">
                            <Col lg="6" md="6">
                                <Col lg="12" md="12" className="photography p-0">
                                    <Link to="/gallery">
                                        <img className="products-photo shadow" alt="Fotografía" src={Fotografia} />
                                        <div className="overlay">Fotografía</div>
                                    </Link>
                                </Col>
                            </Col>
                            <Col lg="6" md="6">
                                <Col lg="12" md="12" className="workshops p-0">
                                    <Link to="/courses">
                                        <img className="products-photo shadow" alt="Para Fotografos" src={ParaFotografos} />
                                        <div className="overlay">Cursos</div>
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </section>
                    <Divider />
                    <section className="section pt-0">
                        <Row>
                            <Col className="text-center p-5">
                                <h3 className="title wow bounceIn pt-0">¿Necesitas más información?</h3>
                                <Link to="/contact"><Button className="wow bounceIn pt-10" variant="info">¡Da click aquí!</Button></Link>
                            </Col>
                        </Row>
                    </section>
                </Container>
                {/* <Row className="stripe">
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto1} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto2} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto3} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto4} />
                    </Col>
                </Row> */}
            </div>
        );
    }
}

export default Home;