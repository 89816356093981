// Includes
import Home from './home';
import Courses from './courses';
import Tutorials from './tutorials';
import Contact from './contact';
import Gallery from './gallery';
import NotFound from './common/notfound';

const routes = [
    {
        path: "/contact",
        component: Contact
    },
    {
        path: "/gallery",
        component: Gallery
    },
    {
        path: "/tutorials",
        component: Tutorials
    },
    {
        path: "/courses",
        component: Courses
    },
    {
        path: "/",
        component: Home
    },
    {
        path: "*",
        component: NotFound
    }
];

export default routes;