import { React, Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WOW from 'wowjs';

// import PielPop from './images/piel-pop.jpg';
// import MariaPrimavera from './images/maria-primavera.jpg';
import Asesoria from './images/asesoria.jpg';
import Iluminacion from './images/iluminacion-de-estudio.jpg';
import Mentorship from './images/1-1-asesoria.jpg';

import './courses.scss';

import Item from '../common/item';


class Courses extends Component {
	constructor() {
		super();
		this.state = {
			showing: 'all'
		}
	}

	componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

	filter = (term) => {
		if(term !== this.state.showing){
			this.setState({showing: term})
		}
	}

	render() {
		return (
			<Container className="content">
				<Row>
					<Col>
						<h1 className="wow bounceIn title flex-left">Cursos</h1>
					</Col>
				</Row>
				<Row className="filters wow bounceIn">
					<Col>
						<div className={this.state.showing === 'all' ? "button selected" : "button"} onClick={() => this.filter('all')}>Todo</div>
						<div className={this.state.showing === 'recorded' ? "button selected" : "button"} onClick={() => this.filter('recorded')}>Pre-grabados</div>
						<div className={this.state.showing === 'live' ? "button selected" : "button"} onClick={() => this.filter('live')}>En línea</div>
						<div className={this.state.showing === 'onsite' ? "button selected" : "button"} onClick={() => this.filter('onsite')}>Presencial</div>
					</Col>
				</Row>
				<Item showing={this.state.showing} 
					  title="Iluminación de Estudio" 
					  mxn="3,000.00" 
					  type="recorded"
					  image={Iluminacion}
					  duration="4 horas y 30 minutos"
					  description={<span>Curso pre-grabado donde se te enviará por medio de un enlace de Google Drive una serie de videos teóricos y prácticos utilizando una, dos, tres y cuatro fuentes de luz.
						<br/><br/>
						Enviaremos un PDF con ilustraciones de todos los esquemas de iluminación que trabajaremos durante el curso.
						<br/><br/>
						Además incluye una edición de cada uno de los esquemas de iluminación</span>} />
				<Item showing={this.state.showing} 
					  title="Asesoría de edición uno a uno en línea" 
					  mxn="1,500.00" 
					  usd="79"
					  type="live" 
					  image={Asesoria}
					  duration="1 hora ó 2 horas"
					  includes={<span>- 1 video<br/>- 9 recursos digitales<br/></span>}
					  description={<span>Editaremos juntos una imágen, para asi poder trabajar con lo que a ti te gustaría que te compartiera.</span>} />
				<Item showing={this.state.showing} 
					  title="Mentoría 1:1" 
					  mxn="17,500.00" 
					  type="onsite" 
					  image={Mentorship}
					  duration="2 días (5 horas/día)"
					  description={<span>- Día 1: Iluminación, práctica con modelo, ventas y tips de marketing (Incluye cena).<br/>- Día 2: Selección de imágenes, edición, exportación.</span>} />
			</Container>
		);
	}
}

export default Courses;
