import React, { useState } from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faClock } from '@fortawesome/free-solid-svg-icons';
import ModalItem from '../modal';

const Item = ({title, mxn, usd, type, image, includes, duration, showing, description}) => {
    const variants = {
        tutorials: 'success',
        actions: 'primary',
        resources: 'danger',
        onsite: 'warning',
        recorded: 'primary',
        live: 'danger'
    }
    const variants_name = {
        tutorials: 'Tutoriales',
        resources: 'Recursos Digitales',
        actions: 'Actions',
        onsite: 'Presencial',
        recorded: 'Pre-grabado',
        live: 'En línea'
    }
    const variant = variants[type];
    const [modalShow, setModalShow] = useState(false);

    return(
        <Row key={title} className={ showing === 'all' || showing === type ? "courses show" : "courses"}>
            <Col>
                <img height="300" src={image} alt={title}></img>
                <div className="item-detail">
                    <span className="item-title">
                        <b>{title}</b>
                        <Badge className="item-badge" variant={variant}>{variants_name[type]}</Badge><br/>
                        {description}
                        <br/><br/>
                        { duration ? <b><FontAwesomeIcon icon={faClock} /> {duration}</b> : ''}
                        <br/><br/>
                        {includes ? <span><em><b>Incluye:</b></em><br/></span> : ''}
                        {includes ? includes : ''}<br/>
                    </span>
                    <span className="item-price">${mxn} MXN{ usd ? `/$${usd} USD`:''}</span>
                    <Button onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faShoppingCart} /> Comprar</Button>
                </div>
                <ModalItem show={modalShow}
                    onHide={() => setModalShow(false)}
                    title={title}
                    paypal={<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="MQKW368PSDYQA" />
                    <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea." />
                    <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1" />
                </form>}/>
            </Col>
        </Row>
    );
}

export default Item;