import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header.scss';

import Logo from './logos/logo-full-width-white.png';

const Header = () => {

    const [ fixed, setFixed ] = useState(false);
    
    const location = useLocation();
    let headerStyle = [];
    let logoStyle = ["logo-container"];

    if(fixed || location.pathname !== '/'){
        headerStyle.push('scrolled');
        logoStyle.push('square');
    }

    const handleScroll = () => {
        if(location.pathname !== '/') return;
        const offset = window.scrollY;

        if(offset > 220){
            setFixed(true);
        }else{
            setFixed(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    return(
        <header className={headerStyle.join(" ")}>
            <div className={logoStyle.join(" ")}>
                <img className="logo" alt="Nathalia Gutierrez" src={Logo} />
            </div>
            <div className="site-menu">
                <ul>
                    <Link to="/">
                        <li>Inicio</li>
                    </Link>
                    <Link to="/gallery">
                    <li className={location.pathname === '/gallery' ? 'active' : '' }>Galería</li>
                    </Link>
                    <Link to="/courses">
                        <li className={location.pathname === '/courses' ? 'active' : '' }>Cursos</li>
                    </Link>
                    <Link to="/tutorials">
                        <li className={location.pathname === '/tutorials' ? 'active' : '' }>Tutoriales</li>
                    </Link>
                    <Link to="/contact">
                        <li className={location.pathname === '/contact' ? 'active' : '' }>Contacto</li>
                    </Link>
                </ul>
            </div>
        </header>
    );
}

export default Header;